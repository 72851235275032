//Bootstrap Dropdown
.dropdown-toggle::after {
  display: none;
}

// Feather Icon
i-feather {
  height: 20px !important;
  width: 20px !important;
}

// // Side Menu
#side-menu i-feather {
  height: 16px !important;
  width: 16px !important;
  line-height: 16px;
}

// Calendar
.fc .fc-col-header-cell-cushion {
  padding: 8px 4px !important;
  color: $border-color !important;
}

// Select2gray-dark
@import "@ng-select/ng-select/themes/default.theme.css";

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: $primary;
  border-color: $primary;
  margin-bottom: 0;
  margin-right: 0;
  font-weight: 400;
  display: inline-block;
  vertical-align: middle;
  border-radius: 20px;
  padding: 4px 10px;
  font-size: 12px;
  color: $white;
  margin-bottom: 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  gap: 5px;
}

.multi_default .ng-value-icon {
  display: none !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: 1px solid #b8dbff;
  font-size: 16px;
  line-height: 16px;
  padding-left: 0;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
  background-color: $gray-600 !important;
}

/**
*   Projects
*/
@import "dropzone/dist/min/dropzone.min.css";

.dropzone .dz-message {
  font-size: 24px;
  min-height: 150px !important;
  border: none !important;
  background: #fff !important;
  color: #495057 !important;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.current .step-indicator {
  background-color: rgba($primary, 0.2);
  color: $primary;
  border: 1px solid rgba($primary, 0.2) !important;
  border-color: rgba($primary, 0.2);
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li .step-indicator {
  border: 1px solid rgba($primary, 0.2) !important;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li .step-indicator,
aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.navigable a:hover .step-indicator {
  font-size: 20px;
  color: $primary !important;
  border: 1px solid $blue-200;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.current .step-indicator {
  border: 1px solid $blue-200;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li:not(:last-child):after {
  width: 75%;
  height: 2px;
  left: 62%;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.done .step-indicator {
  background-color: $blue-200;
  color: $primary;
  border-color: $blue-200;
}

/**
*   Profile page
*/
//Swiper
.swiper-sliders .swiper-button-next,
.swiper-sliders .swiper-button-prev {
  height: 30px !important;
  width: 30px !important;
  margin: auto 0;
  color: #fff !important;
  background-color: #039ef5;
}

.swiper-sliders .swiper-button-next
{
  right: 0 !important;
}

.swiper-sliders .swiper-button-prev{
  left: 0 !important;
}

.swiper-sliders .swiper-button-next:after,
.swiper-sliders .swiper-button-prev:after {
  font-size: 18px !important;
}

.swiper-sliders .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 !important
}

/**
* Faqs Page
*/
.general_faq .card-header {
  padding: 0;
  border: none;
}

.general_faq .card {
  box-shadow: none;
  margin: 0;
  border: none;
}

.general_faq .card-body {
  border-bottom: 1px solid $gray-200;
}

.general_faq .btn-link {
  width: 100%;
  padding: 0;
  border: none;
}

.general_faq .collapsed .accordion-button {
  background-color: transparent;
  color: $gray-700;
  box-shadow: none;
}

.accordion_exp .card {
  border: 1px solid $gray-200 !important;
}

.accordion_exp .card-body {
  border: none !important;
}

// table
// Default styles for the table headers
.datatables {
  thead {
    tr {
      th {
        position: relative;
        cursor: pointer;

        &.sortable {
          &:before {
            content: "\F0143";
            position: absolute;
            right: 0;
            top: 7px;
            font: normal normal normal 24px/1 "Material Design Icons";
            opacity: 0.3;
            display: inline-block;
          }

          &:after {
            content: "\F0140";
            position: absolute;
            right: 0;
            top: 14px;
            opacity: 0.3;
            font: normal normal normal 24px/1 "Material Design Icons";
            display: inline-block;
          }
        }


        // Sorting direction styles
        &.asc::before {
          opacity: 1;
        }

        &.desc::after {
          opacity: 1;
        }

        // Hide sorting icons for non-sortable columns
        &:not(.sortable) {
          cursor: default;

          &::before,
          &::after {
            display: none;
          }
        }
      }
    }
  }
}


/**
*   Ui Elements
*/
.alert-dismissible {
  .btn-close {
    font-size: 10px;
    padding: $alert-padding-y * 1.4 $alert-padding-x;
    background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat;
  }
}

.custom-alert {
  .close {
    background: transparent;
    border: 0;
    font-size: 10px;
    padding: 1.35rem 1.25rem;
    background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.5;
    width: 1em;
    height: 1em;
    z-index: 2;
    color: $btn-close-color;

    span {
      display: none;
    }
  }
}

// Carousel
.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: $white;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

.carousel-dark .carousel-indicators li {
  background-color: $black;
}

// Colors
.col-xl-3:last-child .card .overflow-hidden {
  background-color: transparent !important;
}

/**
* Extended Ui
*/
// LightBox
@import "ngx-lightbox/lightbox.css";

.vimeo_video iframe {
  width: 100%;
}

.vimeo_video #player {
  max-width: 100% !important;
}

// Range Slider
.ngx-slider .ngx-slider-pointer {
  cursor: pointer !important;
  width: 20px !important;
  height: 20px !important;
  top: -7px !important;
  right: -10px !important;
  background-color: $white !important;
  z-index: 3 !important;
  border-radius: 50% !important;
  border: 1px solid $gray-300;
  box-shadow: inset 0 0 1px $white, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
}

.ngx-slider .ngx-slider-pointer:after {
  display: none;
}

// Sweet alert
.swal2-styled.swal2-confirm:focus {
  box-shadow: none !important;
}

.swal2-icon.swal2-question {
  border-color: $info !important;
  color: $info !important;
}

.swal2-icon.swal2-warning {
  border-color: $warning !important;
  color: $warning !important;
}

// Rating
.stars {
  font-size: 30px;
  color: $warning;
}

.star {
  position: relative;
  display: inline-block;
  font-size: 30px;
  color: $warning;
}

.star_fill {
  color: $gray-500;
}

.full {
  color: #b0c4de;
}

.half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: $warning;
}

.filled {
  color: #1e90ff;
}

.bad {
  color: #deb0b0;
}

.filled.bad {
  color: #ff1e1e;
}

// Swiper Slider
.swiper-pagination {
  transform: translateX(-50%) !important;
}

swiper>.swiper.s-wrapper .swiper-pagination .swiper-pagination-handle {
  line-height: 0;
  padding: 0 2px !important;
}

/**
* Forms
*/
// flatpicker
@import 'flatpickr/dist/flatpickr.css';

// Advance Form
.color_picker {
  width: auto;
  margin: 0 auto;
  display: inline-block;
}

.color_picker .input-group-text {
  padding: 20px;
}

/**
* Icons
*/
// Feather Icon
.icon-demo-content i-feather {
  width: 40px !important;
  height: 40px !important;
  line-height: 36px;
  display: block;
  text-align: center;
  margin: 0 auto;
}

.icon-demo-content span {
  margin-top: 8px;
  display: inline-block;
}

body[data-bs-theme="dark"] {

  // Calender
  .fc-theme-standard .fc-scrollgrid,
  .fc-theme-standard td,
  .fc-theme-standard th {
    border: 1px solid $gray-200;
  }

  .fc .fc-daygrid-day-number,
  .fc .fc-col-header-cell-cushion {
    color: $white;
  }

  .fc .fc-col-header-cell-cushion {
    color: $gray-400 !important;
  }

  .ng-select.ng-select-opened>.ng-select-container {
    background: lighten($gray-700, 2%);
  }

  .ng-select .ng-select-container {
    background-color: lighten($gray-700, 2%);
    border: 1px solid $gray-200;
  }

  .ng-dropdown-panel {
    background-color: lighten($gray-700, 2%);
    border: 1px solid $gray-600;
  }

  /**
    * Projects
    */
  aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li:not(:last-child):after {
    background-color: $gray-600;
  }

  //Project create
  .dropzone {
    background-color: lighten($gray-700, 2%) !important;
    border-color: $gray-600 !important;
  }

  .dropzone .dz-message {
    background-color: lighten($gray-700, 2%) !important;
    color: $white !important;
  }

  /**
    *   Faqs Page
    */
  .general_faq .collapsed .accordion-button {
    color: $white;
    border-bottom: 1px solid $gray-600;
    box-shadow: none;
    background-color: transparent;
  }

  .general_faq .card-body {
    border-bottom: 1px solid $gray-600;
  }

  .general_faq .card {
    box-shadow: none;
  }

  /**
    *  Ui Elements
    */
  // Alert
  .alert-outline.custom-alert,
  .alert-top-border.custom-alert {
    .close {
      background: transparent;
      border: 0;
      font-size: 10px;
      padding: 1.35rem 1.25rem;
      background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0.5;
      width: 1em;
      height: 1em;
      z-index: 2;
      color: $btn-close-color;

      span {
        display: none;
      }
    }
  }

  /**
  * Range Slider
  */
  .ngx-slider .ngx-slider-bar {
    background-color: lighten($gray-700, 2%);
  }
}

body {
  * {
    outline: none;
  }
}

.switch-pane {
  color: white;
}


@media screen and (max-width: 425px) {
  .otp-input {
    width: 60px !important;
    height: 60 !important;
  }
}

.NgxEditor__Content {
  height: 200px !important;
}

.fc .fc-button-primary {
  background-color: $primary !important;
  border-color: $primary !important;
}

// No bookings
.no-bookings {
  font-size: 15px;
  font-weight: bold;
  color: #7b8190 !important;
  padding: 20px !important;
}

// Form Button
.w-md {
  min-width: 110px;
}
.submit_btn {
  background-color: #038edc !important;
  border-color: #038edc !important;
}
.submit_btn:hover {
  background-color: #0379bb !important;
  border-color: #0272b0 !important;
}
.btn-transparent {
  background-color: transparent !important;
  border: 1px solid #eff0f2 !important;
  color: rgba(33, 37, 41, 0.75) !important;
}

// Form Input
.form-input-bg {
  background-color: #f8f9fa !important;
  border-color: #eff0f2 !important;
}
.form-input-bg:focus {
  border-color: #81c7ee !important;
  box-shadow: none !important;
}
.form-input-radio {
  border-color: #e2e5e8 !important;
}
.form-input-radio:checked,.form-check-input:checked {
  background-color: #038edc !important;
  border-color: #038edc !important;
}

// Contact Card
.contact-card-bg {
  background-color: #f5f6f8 !important;
  border-color: #e2e5e8 !important;
}

// Modal
.delete-modal-header {
  padding: 1rem 1rem !important;
}
.modal .modal-content .modal-body {
  padding: 1rem !important;
}
.modal .modal-content {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 431px;
  overflow: auto;
  border-radius: 0;
  display: unset !important;
  flex-direction: unset !important;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}
.modal.show .modal-dialog {
  position: fixed;
  top: -30px;
  right: 0;
  height: 100%;
  max-height: 100%;
  width: 431px; 
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1060;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
button.btn-close:focus {
  box-shadow: none !important;
}
.button-booking-danger {
  color: #F74D4D !important;
}
.button-booking-success {
  color: #035A55 !important;
}
.check-text-size {
  font-size: 14px !important;
}
.check-text-color {
  color: #495057 !important;
  text-align: center;
  font-family: Roboto !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1.2 !important;
  position: relative;
  top: 15px;
}
.modal-cancel-title {
  font-size: 20px !important;
}
.closeCancelBtn {
  width: 14px !important;
  height: 14px !important;
  position: relative;
  top: -2px;
}
.cancelModalOpen.modal .modal-content,
.modalOpenUSP.modal .modal-content,
.deleteModalOpen.modal .modal-content {
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 585px;
  display: flex !important;
  flex-direction: column !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
  transition: none;
  height: unset !important;
}
.cancelModalOpen.modal.show .modal-dialog,
.modalOpenUSP.modal.show .modal-dialog,
.deleteModalOpen.modal.show .modal-dialog {
  position: relative;
  height: unset !important;
  max-height: unset !important;
  width: 585px;
}
.modal-dialog {
  max-width: unset !important;
  margin-left: unset !important;
  margin-right: unset !important;
}
.cancelModalOpen .modal-dialog,
.modalOpenUSP .modal-dialog,
.deleteModalOpen .modal-dialog {
  max-width: var(--bs-modal-width) !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

.modal .modal-header .modal-title {
  padding-bottom: 8px;
}
button.btn-close{
  position: relative;
  top: -3px;
}
.modal .delete-modal-footer {
  border-top: 1px solid #eff0f2 !important;
}
// Cursor
.cursor-pointer {
  cursor: pointer;
}
// datepicker
.input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}
.input-container input {
  padding-right: 40px; /* Adjust based on the icon's size */
  width: 260px;
  background-color: #f8f9fa;
  margin-bottom: 20px;
}
.input-container .calendar-icon {
  position: absolute;
  right: 10px; /* Adjust based on your preference */
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Prevent the icon from blocking input interactions */
  color: #6c757d; /* Adjust color to match your theme */
}
.md-drppicker.double {
  width: 550px !important;
}

// Hotel Gallery
.gallery-container {
  width: 100%;
  height: 220px;
  overflow: hidden;
}
.gallery-img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.seasonal-tab {
  flex-wrap: nowrap !important;
  justify-content: center;
}
.nav-pills {
  --bs-nav-pills-link-active-bg: #dc3545 !important;
}
.season-tab-size {
  font-size: 12px !important;
}
.icon-16 {
  font-size: 16px;
}
.seasonal-box-shadow {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
}
.btn-check:checked + .seasonal-check, :not(.btn-check) + .seasonal-check:active, .seasonal-check:first-child:active, .seasonal-check.active, .seasonal-check.show {
  background: #F74D4D !important;
  border: 1px solid #F74D4D !important;
  color: #fff !important;
}
.form-booking-size {
  width: 156px !important;
  position: absolute;
  right: 15px;
  z-index: 1;
  margin-top: -48px;
}
.form-booking-search {
  right: 93px !important;
  font-size: 1rem;
  font-weight: 500;
}
.data-table-card {
  margin-top: 40px;
}
@media(max-width: 575px) and (min-width: 300px){
  .data-table-card {
    margin-top: 0px;
  }
  .modal .modal-content {
    width: 250px;
  }
  .contact-desc-alert {
    font-size: 14px;
    white-space: nowrap;
    margin-top: 1px;
  }
  .contact-alert {
    position: fixed !important;
    width: 92%;
    top: 6%;
    z-index: 1;
  }
  ol.breadcrumb {
    font-size: 14px;
  }
}
.print-btn {
  border: 1px solid #228edc !important;
  color: #228edc !important;
  padding: 3px 12px !important;
}
.print-icon {
  position: relative;
  top: 3px;
}
.print-text {
  position: relative;
  top: -2px;
  left: -3px;
}
button.btn.btn-outline-primary.print-btn:hover {
  background-color: #228ddb;
  border-color: #228ddb;
}
button.btn.btn-outline-primary.print-btn:hover .print-text,
button.btn.btn-outline-primary.print-btn:hover .print-icon {
  color: #fff !important;
}
/* Print-specific styles */
@media print {
  .print-btn {
    display: none; /* Hide the print button when printing */
  }

  /* Other custom styles for printing */
  .custom-print-layout {
    margin: 0;
    padding: 0;
    width: 100%;
  }
}

.table-price {
  margin-left: 10px;
  margin-bottom: 5px;
}
.table-price td.update-price-list {
  position: relative;
  left: 22px;
}
.table-price td.price-list-table {
  left: 24px !important;
}
.table-price td.reduced-price {
  left: 3px;
}
.payment-image {
  width: 250px;
  height: auto;
  margin: 10px auto 30px auto;
  display: block;
}
app-pagetitle h4,
.modal-title, 
.card-title,
.booking-text-color,
.text-room-desc,
textarea.textarea-room {
  color: #495057 !important;
}
.add-room-card {
  max-width: 100%;
  min-height: 150px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
}
.add-room-text {
  place-content: center;
  cursor: pointer;
}
button.btn.view-booking-btn {
  background-color: #f7f8fa;
  color: #212529;
  border-radius: 5px;
  width: 84px;
}
.text-room-color {
  color: #757A81 !important;
  width: 200px;
}
.text-room-desc,
textarea.textarea-room,
.amenities-desc-msg {
  font-size: 14px !important;
}
textarea.textarea-room {
  height: 210px;
}
textarea.textarea-msg {
  height: 125px !important;
}
.text-room-size {
  width: 140px !important;
}
.extra-bed {
  width: 230px !important;
}
.availability-desc,
.occupancy-desc {
  width: 70px !important;
}
.hide-room-switch {
  right: 220px;
}
.hide-room-view {
  right: 0;
  bottom: -20px;
}
.form-hide-switch {
  top: 4px;
}
.form-hide-view-switch {
  top: 15px;
  float: right !important;
  margin-left: 1.4em !important;
}
.add-room-btn {
  right: 35px;
  margin-top: -45px;
  border-radius: 50px !important;
  font-size: 14px !important;
}
.add-msg-btn,
.add-service-btn {
  right: 35px;
  border-radius: 50px !important;
  font-size: 14px !important;
  margin-top: -35px !important;
}
.rooms-view-screen,
.rooms-edit-screen {
  position: relative;
  top: -15px;
}
.delete-icon-danger {
  color: #F74D4D !important;
}
.cursor-move {
  cursor: move;
}
.room-card {
  padding: 20px;
  background: #FFF;
  border: 1px solid #FFF !important;
  border-radius: 8px !important;
  cursor: grab;
}
.publish-btn {
  left: 15px;
  width: 80px;
  top: 58px;
  padding: 4px 6px !important;
  color: #343A40 !important;
  font-size: 12px !important;
  background-color: rgba(171, 174, 174, 0.50) !important;
  border-color: rgba(171, 174, 174, 0.50) !important;
}
.drag-icon-room {
  width: 24px;
  height: 24px;
  right: 15px;
  top: 15px;
}
.edit-room-icon {
  position: relative;
  left: -25px;
  float: right;
  top: -5px;
}
.cursor-move {
  cursor: move;
}
.card-title.room-view-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
/* Ensure the image is contained properly and has the hover effect */
.room-image-container {
  position: relative;
  display: inline-block;
}

/* Apply hover effect to the image container */
.room-image-container:hover .room-image {
  opacity: 0.9; /* Darken the image on hover */
}

/* Dark overlay that will appear on hover */
.room-image-container:hover .overlay {
  opacity: 1;
}

/* Overlay dark effect */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Darken the image */
  opacity: 0;
  transition: all 0.3s ease;
  z-index: 1;
}
/* Positioning and styling the edit button */
.edit-room-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  padding: 10px 30px !important;
  border: 1px solid white;
  color: white !important;
  font-size: 12px !important;
  display: none !important; /* Hide by default */
  transition: all 0.3s ease;
  z-index: 2;
  border-color: #ffffff !important;
  border-radius: 50px !important;
  background-color: transparent !important;
}

/* Show the edit button on hover */
.room-image-container:hover .edit-room-img {
  display: block !important;
}
.contact-desc-msg {
  color: #777C81 !important;
  font-size: 14px !important;
}
.alert-dismissible.contact-alert .btn-close {
  top: 2px !important;
}
.online_text {
  top: -1px;
  left: 3px;
}
.dateTimeTempotraryMessage {
  position: relative;
  left: -24px;
}
.calendar-icon-img {
  position: absolute;
  right: 10px;
  bottom: 1px;
}
.space-room-desc {
  margin-bottom: 10px;
  left: -15px; 
  white-space: nowrap;
}
.icon-space-room {
  margin-top: 3px;
}
.form-switch-md .form-check-input {
  transform: scale(1.2);  /* Adjust scale as needed */
}
.label-switch-text {
  top: -1px;
  left: 4px;
  color: #495057 !important;
}
/* Base styling for the pills */
#categories-pill .categories,
#add-manageres-pill .categories {
  display: inline-block;
  padding: 4px 10px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  border-radius: 99px; /* Rounded edges for the pill */
  color: #495057;  /* Dark gray text for inactive pills */
  background-color: #F2F3FE;  /* Light gray background for inactive pills */
  border: 1px solid #F2F3FE;  /* Gray border */
  margin: 0 0 4px 0;
  cursor: pointer;
  white-space: nowrap;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
}

/* Active (checked) pills */
#categories-pill .categories.active {
  background-color: #008EDC !important;  /* Blue background for active pills */
  color: #FFFFFF;  /* White text on active pills */
  border-color: #008EDC;  /* Match border with background */
}

/* Inactive (unchecked) pills */
#categories-pill .categories.inactive {
  background-color: #F2F3FE !important;  /* Light background for inactive pills */
  color: #495057;  /* Dark gray text for inactive pills */
  border-color: #F2F3FE;  /* Gray border for inactive pills */
}

/* Hover effect for better interaction */
#categories-pill .categories:hover {
  background-color: #F2F3FE;
  transform: translateY(-2px);  /* Slight lift effect */
}

/* Active hover effect */
#categories-pill .categories.active:hover {
  background-color: #008EDC;
  border-color: #008EDC;
  transform: translateY(-2px);
}

/* Inactive hover effect */
#categories-pill .categories.inactive:hover {
  background-color: #F2F3FE !important;
  transform: translateY(-2px);
}
.form-room-check {
  padding: 0 0 10px 0 !important;
}
.btn-pills-close {
  left: 1px;
}
.bg-publish-online {
  background-color: rgba(81, 210, 140, 0.50) !important;
  border-color: rgba(81, 210, 140, 0.50) !important;
}
.horizontal-space {
  border: 1px solid #E3E4E8;
  margin: 15px 0px;
  opacity: 0.5;
}
.contact-hotel-label {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 1;
  letter-spacing: 0.32px;
  margin-bottom: 15px;
}
.no-bookings-req {
  height: 30px;
}
.contact-view {
  left: 0;
  padding-bottom: 8px;
}
.contact-hide-switch {
  left: -5px;
}
.contact-label-switch {
  left: 18px;
}
.horiz-line {
  margin: 1rem 12px !important;
  max-width: 515px !important;
  border-color: #E3E4E8;
  opacity: 1;
}
.add-rooms-edit,.add-rooms-overview {
  margin-top: 5px !important;
}
.room-image {
  max-height: 187px;
  min-height: 187px;
  max-width: 310px !important;
  min-width: 310px;
  display: block;
  object-fit: cover;
}
.drop-area {
  width: 351px !important;
  height: 390px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px dashed #007bff;
  background-color: #f1f9ff;
  padding: 20px;
  margin: 0 0px 0 10px;
  border-radius: 8px !important;
  z-index: 10;
  transition: top 0.1s ease;
}
.dotted-border {
  border: 2px dashed #007bff;
  padding: 20px;
  background-color: #f1f9ff;
}
.room-card:active {
  cursor: grabbing;
}
.room-card-container.drop-indicator {
  border-right: 3px solid #228edb;
  transition: border 0.2s ease-in-out;
}
.md-drppicker {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 1050; 
  display: block !important;
}
.md-drppicker.hidden {
  display: none !important;
}

.md-drppicker .calendar {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}
.discount-hotel-info {
  color: #777C81;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}
.add-period-discount {
  background: transparent !important;
  color: #008EDC !important;
}
.add-period-discount:hover {
  color: #fff !important;
}
.till-late{
  color: #495057 !important;
}
.border-red {
  border: 1px solid #F34E4E !important;
}
.precise-location {
  color: #777C81;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}
.google-link-locate {
  color: #008EDC;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
  text-decoration-line: underline !important;
  text-decoration-style: solid !important;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto !important;
  text-underline-offset: auto;
  text-underline-position: from-font;
}
.precise-locate-map-text {
  color: #495057;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.28px;
  position: relative;
  left: -12px;
  top: 4px;
  border: none;
  width: 100%;
  background: #F8F9FA;
}
.horiz-precise {
  background: #E3E4E8 !important;
  opacity: 0.1 !important;
}
.hotel-descriptipon-text {
  color: #777C81;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.28px;
}
.primary-desc,
.secondary-desc,
.language-desc,
.meta-title,
.meta-description,
.hotel-settings-status,
.hotel-members-only,
.hotel-settings-total-score,
.hotal-total-score,
.hotel-settings-trust-you,
.hotel-trust-you,
.hotel-user-rating,
.user-rating,
.hotel-settings-labels,
.members-only,
.booking-mode,
.available-notify,
.hotel-yield-price,
.hotel-last-minute-yield,
.hotel-status,
.hotel-booking-mode,
.hotel-availability-notifications,
.mininum-length-stay,
.booking-guarantee,
.register-input,
.textarea-notes,
.registration-settings,
.internal-notes,
.channel-manager,
.channel-logs,
.settings-checkbox {
  color: #495057 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600;
  line-height: 100% !important;
  letter-spacing: 0.28px !important;
}
.lang-fwt-weight {
  font-weight: 400 !important;
}
.language-name,
.seo-content,
.date-range-settings {
  color: #757A81;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.28px;
}
.language-container {
  padding: 10px;
  margin: 0 !important;
  background: #f8f9fa;
}
.cancel_btn {
  color: #008EDC !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.28px;
  position: absolute;
  right: 13%;
  top: 3px;
}
.submit_btn_border {
  padding: 12px 15px !important;
  border-radius: 50px !important;
}
.input-container input.hotel-info {
  width: 100%;
  margin-bottom: unset !important;
}
.w-24 {
  width: 24px !important;
}
.h-24 {
  height: 24px !important;
}
.available-users,
.assigned-users {
  color: #495057 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 130% !important;
  letter-spacing: 0.28px !important;
}
.card-manager-body {
  padding: 30px 20px 30px 30px !important;
}
.no-users,
.managers-list,
.seo-content-desc {
  color: #777C81 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  letter-spacing: 0.28px !important;
}
#available-users::placeholder {
  color: #757A81 !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.28px;
}
#available-users {
  background: url('../images/search.svg') no-repeat left 10px center;
  padding-left: 40px; /* Ensure padding to make space for the icon */
  background-size: 18px 18px; /* Adjust icon size */
}
.cancel_btn_hotel_managers {
  position: relative !important;
  left: unset !important;
  right: 10% !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 100% !important;
}
.managers-added-list {
  display: flex;
  height: 34px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 99px;
  background: #F2F3FE;
}
.mb-16 {
  margin-bottom: 16px;
}
/* Active (checked) manager pills */
#add-manageres-pill .categories {
  background-color: #008EDC !important;
  color: #FFFFFF !important;
  border-color: #008EDC !important; 
}
.card.card-h-100 {
  border: 1px solid #ffffff;
}
.submit-manager-btn {
  right: 15px;
  padding: 12px 15px !important;
  width: 120px;
  white-space: nowrap;
  color: #FFF !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 100% !important;
  letter-spacing: 0.28px;
  height: 38px;
  gap: 8px;
}
.card.border-discount-price {
  border: 1px solid #E2E7F1 !important;
  margin-bottom: 10px;
  padding: 16px !important;
  border-radius: 12px !important;
}
.calendar-msg-icon {
  bottom: 20px !important;
}
.plus-code-input {
  --bs-card-bg: #EFF0F2 !important;
  --bs-card-bg: #F8F9FA !important;
}
.h-40 {
  height: 40px !important;
}
.w-120 {
  width: 120px !important;
}
.border-red {
  border: 1px solid #F34E4E !important;
}
.count-label {
  position: absolute;
  bottom: 12px;
  right: 0px;
  color: #757A81;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.28px;
}
textarea.form-control.usp {
  resize: none;
  padding: 0.475rem 60px 0.475rem 0.75rem !important;
  height: 40px;
  min-height: 40px;
  margin-bottom: 0;
  overflow: hidden;
}
.lang-field{
  border-radius: 0.25rem;
}
.text-room-color {
  color: #757A81 !important;
  width: 200px;
  margin-bottom: -2px;
  display: flex;
  align-items: center;
}
.textarea-container {
  flex-grow: 1;
  margin-right: -15px;
}
.usp-main {
  top: 18px;
}
.disabled-service-btn,
.disabled-service-btn:hover {
  pointer-events: auto !important;
  background-color: #858EA4 !important;
  border-color: #858EA4 !important;
  opacity: 1 !important;
  cursor: not-allowed !important;
}
.border-bottom-none {
  border-bottom: none !important;
}
.modalOpenUSP.modal .modal-content {
  width: 355px !important;
}
.deleteModalOpen.modal .modal-content {
  width: 500px !important;
}
.warning-circle-main{
  position: relative;
  margin: 0 auto;
  display: block;
  top: 12px;
}
.warning-circle-inner {
  position: absolute;
  left: 50%;
  transform: translate(-50%,0);
  margin-top: 22px;
}
.error-border-label {
  margin-bottom: -2px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-left: 1px solid #F34E4E !important;
  border-top: 1px solid #F34E4E !important;
  border-bottom: 1px solid #F34E4E !important;
}
.error-border-input {
  margin-bottom: -2px !important;
  border-left: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-right: 1px solid #F34E4E !important;
  border-top: 1px solid #F34E4E !important;
  border-bottom: 1px solid #F34E4E !important;
}
.error-border-count {
  color: #F34E4E !important;
}

.hotel-info-superior,
.open-till-date {
  position: relative;
  top: -10px;
}
.hotal-total-score,
.hotel-trust-you,
.user-rating,
.register-input {
  font-weight: 500 !important;
  text-align: right;
}
.user-rating {
  text-align: left !important;
  border: none !important;
  position: relative;
  top: -15px;
}
.register-input {
  top: -10px !important;
}
.members-only,
.available-notify,
.hotel-status,
.hotel-booking-mode {
  font-weight: 400 !important;
}
.hotel-availability-notifications,
.mininum-length-stay,
.textarea-notes {
  line-height: 130% !important;
}
.add-note-btn {
  background: transparent !important;
  color: #228edc !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.28px;
  height: 34px;
  width: 126px;
  padding: 0 20px;
  gap: 10px;
  top: 10px;
  left: 10px;
  text-indent: -5px;
}
.add-note-icon {
  position: relative;
  left: -5px;
}
.add-note-btn:hover {
  color: #fff !important;
}
.input-notes-text {
  padding-top: 20px 0 0 0;
  left: 10px;
  top: 25px;
}
.switch-minimum-length {
  top: 4px;
}
.category-settings {
  margin-right: 8px !important;
}
.textarea-notes {
  padding: 16px !important;
  gap: 8px;
  border-radius: 12px !important;
  border: 1px solid #E2E7F1 !important;
  font-weight: 500 !important;
}
.mb-30 {
  margin-bottom: 30px;
}
.settings-checkbox {
  font-weight: 500 !important;
  margin-right: 12px;
}
.add-condition-btn {
  width: fit-content !important;
  padding: 0 20px !important;
  top: 20px !important;
  left: 0 !important;
}