// 
// breadcrumb.scss
//


// Breadcrumb item arrow
.breadcrumb-item {
    >a {
        color: var(--#{$prefix}body-color);
    }

    +.breadcrumb-item {
        &::before {
            font-family: "Material Design Icons";
        }
    }
}