/*
 *  Document   : _modals.scss
 *  Author     : RedStar Template
 *  Description: This scss file for modals style classes
 */
.modal {
  background-color: rgba(238, 238, 238, 0.41);
  //padding: 100px 0 0;
  max-height: 100%;
  width: 100%;

  .modal-header {
    border: none;
    padding: 0px;
    border-bottom: 1px solid #eee;

    .modal-title {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .modal-content {
    border-radius: 10px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.31) !important;
    border: none;
    padding: 10px;

    .modal-body {
      color: #777;
      padding: 15px 25px;
    }
  }

  .modal-footer {
    border: none;
    background-color: transparent;

    .btn {
      margin: 10px;
    }
  }
}

.addContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .form {
    display: flex;
    padding-top: 6px;
  }

  .mat-form-field {
    flex-grow: 1;
  }

  .modalHeader img {
    border-radius: 50%;
  }
}

.modalHeader {
  display: flex;
  align-items: flex-start;
  // justify-content: space-between;
  padding: 0px 10px 0px 10px;
  margin: 10px 10px 0px 10px;

  .modal-about {
    padding: 5px;
    font-weight: 500;
    font-size: 16px;
  }
}

.modal-close-button {
  background-color: transparent !important;
  box-shadow: none !important;
  color: #161d38;
  right: 10px;
  position: absolute !important;
}

@each $key, $val in $colors {
  .modal-col-#{$key} {
    background-color: $val;

    .modal-body,
    .modal-title {
      color: #fff !important;
    }

    .modal-footer {
      background-color: rgba(0, 0, 0, 0.12);

      .btn-link {
        color: #fff !important;

        &:hover,
        &:active,
        &:focus {
          background-color: rgba(0, 0, 0, 0.12);
        }
      }
    }
  }
}

.cdk-overlay-connected-position-bounding-box {
  z-index: 1052 !important;
}

.mat-dialog-content {
  margin: 0px !important;
  padding: 0px !important;
}

.transparent-backdrop {
  background: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
}

.dialog-backdrop {
  background: rgba(0, 0, 0, 0.5);
}

.cdk-overlay-container {
  top: 0 !important;
  position: fixed;
  width: 100%;
  z-index: 1052 !important;
}
.cdk-overlay-pane {
  width: 80% !important;
  height: 80% !important;
  position: static !important;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .cdk-overlay-pane {
    width: 90% !important;
    height: 90% !important;
  }
}

@media (max-width: 480px) {
  .cdk-overlay-pane {
    width: 95% !important;
    height: 95% !important;
  }
}
